import React, { useState, useEffect, useRef } from 'react';
import './certificate.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import logoWhite from '../../Logo-EarthID-white.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from "react-router-dom";
import QRCode from 'qrcode.react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import CertificatePDF from './downloadCert';
import { saveAs } from 'file-saver'; // Import the file-saver library
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import CertificateIcon from '../../img/colorcert.png'
import BadgeIcon from '../../img/badgeIcon.png'
import BadgeImg from '../../img/badgeNew.png'
import badgeIcon from '../../img/badge.png'
import colorBadge from '../../img/colorbadge.png'
import YellowBadge from '../../img/rosette.svg'
import Xlogo from '../../img/icons8-x-60.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faEnvelope, faEllipsisVertical, faIdBadge, faFilePdf, faImage, faShare, faQuestionCircle, faArrowDown, faCaretDown, faPen, faUser, faMedal, faArrowUpRightFromSquare, faCheckCircle, faBitcoinSign, faGlobe, faMobile, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare, faWhatsapp, faFacebookSquare, faWhatsappSquare, faLinkedinIn, faTwitter, faEmber, faBitcoin } from '@fortawesome/free-brands-svg-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';


import { JsonToTable } from "react-json-to-table";
import axios from 'axios';
const moment = require('moment');


const ssiBaseUrl = "https://ssi-stage.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:HveP8Sc6xGJJSZ9sGdDZFdQ6zZM9JZjG5BSg6R1YNuFH;earthid:testnet:fid=0.0.407132";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";

const newIssuerPublicKey = "E3rzEQG1fXSVnGmSdz78MYodtndcv6Q5qy3gdUz1FEi6"


const Training = () => {
    const [responseData, setResponseData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [showCertificate, setShowCertificate] = useState(true);
    //const [showJson, setShowJson] = useState(true);
    const { certType, userName } = useParams();
    const qrCodeUrl = `https://procred.myearth.id/${userName}/credential/${certType}`;

    const pdfContainerRef = useRef();

    const MySwal = withReactContent(Swal);
    //const certificationName = 'Training Certificate';
    const certUrl = `https://procred.myearth.id/${userName}/credential/${certType}`;


   let prefix
   let certificationName
   let certDesc
   let certDesc2
   let badgeImgUrl
   let skills
   let technology
   let linkedInURLNew
   let noBadge
   let newUserName

    // Determine values based on certType
    switch (certType) {
        case 'training':
            certificationName = 'Training Certificate';
            certDesc = (responseData) ? (
                <div className="title1">
                    has successfully gone through the training curriculum and <br />
                    has completed all the training processes of the company.<br />
                    This certificate is issued on:<br />
                    {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                </div>
            ) : '';
            certDesc2 = `This certificate is awarded to acknowledge that ${userName} has satisfactorily fulfilled all the stipulated requirements and has completed the necessary assessments for the training conferred by EarthID company. With this achievement, ${userName} has earned the distinction of certified professional.
            This certificate is a validation of ${userName}'s training accomplishments at the company.`
            prefix = "TrainingCertificate"
            badgeImgUrl = BadgeImg
            skills = {
                skill1 : "Javascript",
                skill2 : "React",
                skill3 : "Node",
                skill4 : "Hashgraph",
            }
            break;
        case 'project-completion':
            certificationName = 'Project Completion Certificate';
            certDesc = (responseData) ? (
                <div className="title1">has succesfully completed the project and <br></br>
                has completed all the project tasks processes of the company.<br></br>
                This certificate is issued on:<br></br>
                {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                </div>
            ) : '';
            certDesc2 = `This certificate is awarded to acknowledge that ${userName} has satisfactorily fulfilled all the stipulated requirements and has completed the necessary processes for the project conferred by EarthID.`
            prefix = "ProjectCompletionCertificate"
            badgeImgUrl = BadgeImg
            skills = {
                skill1 : "Javascript",
                skill2 : "React",
                skill3 : "Node",
                skill4 : "Hashgraph",
            }
            break
        case 'professional-membership':
            certificationName = 'Professional Membership Certificate';
            certDesc = (responseData) ? (
                <div className="title1">has been officially onboarded <br></br>
                as a member of EarthID association.<br></br>
                This certificate is issued on:<br></br>
                {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                </div>
            ) : '';
            certDesc2 = `This certificate is awarded to acknowledge that ${userName} has satisfactorily fulfilled all the stipulated requirements to be part of EarthID Association. `
            prefix = "ProfessionalMembershipCertificate"
            badgeImgUrl = BadgeImg
            skills = {
                skill1 : "Javascript",
                skill2 : "React",
                skill3 : "Node",
                skill4 : "Hashgraph",
            }
            break

            case 'internship':
                newUserName = (responseData ? responseData[0].vc.credentialSubject[0].name : userName)
                certificationName = 'Internship Certificate';
                certDesc = (responseData) ? (
                    <div className="title1">
                        has successfully completed the internship,
                        starting from {moment(responseData[0].vc.credentialSubject[0].internshipDetails.startDate).format('MMMM Do YYYY')} and completing on {moment(responseData[0].vc.credentialSubject[0].internshipDetails.endDate).format('MMMM Do YYYY')}.
                         They have
fulfilled all the requirements and responsibilities of the program.<br /> <br/>
                        This certificate is issued on:<br />
                        {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                    </div>
                ) : '';
                certDesc2 = `This certificate is awarded to acknowledge that ${newUserName} has satisfactorily fulfilled all the stipulated requirements and has 
                successfully completed the internship program at EarthID. With this achievement, ${newUserName} has demonstrated exceptional 
                performance and dedication.`
                prefix = "InternshipCertificate"
                badgeImgUrl = BadgeImg
               // skills = responseData[0].vc.credentialSubject[0].internshipDetails.skillsGained
               // technology = responseData[0].vc.credentialSubject[0].internshipDetails.projects[0].technologies
//                 skills = {
//                     skill1 : "Coding",
//                     skill2 : "Teamwork",
//                     skill3 : "ReactJS",
//                     skill4 : "NodeJS",
//                     skill5 : "APIs",
//                     skill6 : "C++",
//                     skill7:"Market Reserach",
// skill8:"Startup Pitch",
// skill9:"Testing",
// skill10:"Python"
//                 }
                noBadge = true
                break;

                case 'advisor':
                    newUserName = (responseData ? responseData[0].vc.credentialSubject[0].name : userName)
            certificationName = 'Advisory Certificate';
            certDesc = (responseData) ? (
                <div className="title1">has been officially recognized as an advisor for EarthID. 
                This position acknowledges their invaluable contributions and expertise in guiding 
                EarthID toward its vision of achieving social, financial, 
                and digital inclusion through user-centric identity solutions. <br></br><br/>
                This certificate is issued on:<br></br>
                {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                </div>
            ) : '';
            certDesc2 = `This certificate is proudly awarded to ${newUserName} in recognition of their exceptional contributions as an advisor at EarthID. Through their dedicated efforts and valuable support, ${newUserName} plays a vital role in advancing EarthID's mission of promoting social, financial, and digital inclusion through user-centric identity solutions.`
            prefix = "AdvisoryCertificate"
            badgeImgUrl = BadgeImg
            skills = {
                skill1 : "Industry Expertise",
                skill2 : "Strategic Thinking",
                skill3 : "Networking and Connections",
                skill4 : "Mentorship and Coaching",
            }
            noBadge = true
            break
        default:
            certificationName = 'Certificate';
            certDesc = ""
            certDesc2 = ""
            prefix = ""
            badgeImgUrl = ""
            break
    }

    const userNamee = `${prefix}${userName}`;

  //  console.log(skills)

    useEffect(() => {
        const fetchData = async () => {
            try {
                
                console.log('Username', userNamee);
                const cidsResponse = await fetch(`https://procreddb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
                const cidJson = await cidsResponse.json();
               console.log('CidsResponse', cidJson);

                if (cidJson && cidJson.response && cidJson.response.length > 0) {
                    setResponseData(cidJson.response);
                } else {
                    console.log('Certificate not generated');
                    Swal.fire('Certificate Not Generated');
                }
            } catch (error) {
                console.log('Error:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [prefix, userName]);

    // useEffect(() => {


    //     const fetchData = async () => {
    //         try {
    //             const prefix = 'TrainingCertificate'; // Replace with your desired prefix
    //             const userNamee = `${prefix}${userName}`;
    //             console.log('Username', userNamee)
    //             const cidsResponse = await fetch(`https://procreddb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
    //             const cidJson = await cidsResponse.json();
    //             console.log('CidsResponse', cidJson);

    //             if (cidJson && cidJson.response && cidJson.response.length > 0) {
    //                 // const response = await fetch('https://procreddb.myearth.id/api/vc/fetchVCMain', {
    //                 //     method: 'POST',
    //                 //     headers: {
    //                 //         'Accept': 'application/vnd.ipld.car',
    //                 //         'Content-Type': 'application/json',
    //                 //     },
    //                 //     body: JSON.stringify({
    //                 //         cids: [
    //                 //             cidJson.cids[cidJson.cids.length - 1]
    //                 //         ],
    //                 //     }),
    //                 // });
    //                 setResponseData(cidJson.response)
    //                 // if (response.ok) {
    //                 //     const json = await response.json();
    //                 //     console.log(json);
    //                 //     setResponseData(json);
    //                 // } else {
    //                 //     console.log('Request failed with status code:', response.status);
    //                 // }
    //             } else {
    //                 console.log('Certificate not generated');
    //                 Swal.fire('Certificate Not Generated');
    //             }
    //         } catch (error) {
    //             console.log('Error:', error);
    //         }
    //         finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, []);





    useEffect(() => {
        if (isButtonClicked && responseData) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true]);
            setVerificationData([]);

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([true, true, true, true, true]);
                setVerificationData([
                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                   noBadge ? 'NA' : moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                    "EarthID",
                    noBadge ? newUserName : userName
                ]);

                setTimeout(() => {
                    setIsVerificationLoading([false, true, true, true, true]);
                    setVerificationData([
                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                        noBadge ? 'NA' : moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                        "EarthID",
                        noBadge ? newUserName : userName
                    ]);

                    setTimeout(() => {
                        setIsVerificationLoading([false, false, true, true, true]);
                        setVerificationData([
                            moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                            noBadge ? 'NA' : moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                            "EarthID",
                            noBadge ? newUserName : userName
                        ]);

                        setTimeout(() => {
                            setIsVerificationLoading([false, false, false, true, true]);
                            setVerificationData([
                                moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                noBadge ? 'NA' : moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                                "EarthID",
                                noBadge ? newUserName : userName
                            ]);

                            setTimeout(() => {
                                setIsVerificationLoading([false, false, false, false, true]);
                                setVerificationData([
                                    moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                    noBadge ? 'NA' : moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                                    "EarthID",
                                    noBadge ? newUserName : userName
                                ]);

                                setTimeout(() => {
                                    setIsVerificationLoading([false, false, false, false, false]);
                                    setVerificationData([
                                        moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY'),
                                        noBadge ? 'NA' : moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY'),
                                        "EarthID",
                                        noBadge ? newUserName : userName
                                    ]);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 12000);
        }
    }, [isButtonClicked, responseData]);


    const extractLastPart = (responseData) => {
        if (responseData && responseData.length > 0 && responseData[0].vc.id) {
            const id = responseData[0].vc.id;
            const parts = id.split('-');
            const lastPart = parts[parts.length - 1];
            return lastPart;
        }
        return null;
    }

    const lastPart = extractLastPart(responseData);

    const getYearandMonth = (responseData) => {
        if (responseData && responseData.length > 0) {
            const issueYear = moment(responseData[0].vc.issuanceDate).format('YYYY')
            console.log(issueYear)
            const issueMonth = moment(responseData[0].vc.issuanceDate).format('MM')
            console.log(issueMonth)

            const expiryYear = moment(responseData[0].vc.expirationDate).format('YYYY')
            console.log(expiryYear)
            const expiryMonth = moment(responseData[0].vc.expirationDate).format('MM')
            console.log(expiryMonth)
            // Return an object containing the formatted values
            return {
                issueYear,
                issueMonth,
                expiryYear,
                expiryMonth,
            };
        }
        return null;
    }

    const dateData = getYearandMonth(responseData)
    console.log(dateData)

    const generateLinkedInURL = (certificationName, dateData, certUrl) => {
        if (!dateData) {
            // Handle the case when dateData is null (responseData is not available or empty)
            console.error("dateData is null. Cannot generate LinkedIn URL.");
            return null;
        }

        let linkedInURL = `https://www.linkedin.com/profile/add?startTask=${encodeURIComponent(
            certificationName
        )}&name=${encodeURIComponent(certificationName)}&organizationId=18693485&issueYear=${encodeURIComponent(dateData.issueYear)}&issueMonth=${encodeURIComponent(
            dateData.issueMonth
        )}&certUrl=${encodeURIComponent(certUrl)}&certId=${encodeURIComponent(lastPart)}`;
    
        if (!noBadge) {
            linkedInURL += `&expirationYear=${encodeURIComponent(dateData.expiryYear)}&expirationMonth=${encodeURIComponent(dateData.expiryMonth)}`;
        }
    
        return linkedInURL;

        return linkedInURL;
    };

    const linkedInURL = generateLinkedInURL(
        certificationName,
        dateData,
        certUrl
    );



   

    //Verify Api

    const createUserSignature = async (documentMetadata) => {
        try {
            const userDid = await createUserDid();
            const payload = {
                payload: {
                    credentialSubject: {
                        id: userDid,
                        pdfMetaData: documentMetadata,
                    },
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    privateKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log(response.data)
            return response.data.Signature;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    const verifyVC = async () => {
        try {

            //const signature = await createUserSignature();

            const data = {
                "credentials": {
                    ...responseData[0].vc
                }
            }
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': newIssuerPublicKey,
                    //'userSignature': signature, // Call the createUserSignature function
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };

    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsButtonClicked(true);
        setIsVerifying(true);


        // Call verifyVC() here
        verifyVC().then((verificationResult) => {
            // Process the verification result and update state variables accordingly
            setIsVerifying(false);
            // Update other state variables based on verification result
            // Example:
            // setIsVerificationLoading([...]);
            // setVerificationData([...]);
        }).catch((error) => {
            // Handle error
            setIsVerifying(false);
            // Display error message or take appropriate action
        });
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    // function toggleView() {
    //     setShowJson(!showJson);
    //   }

    const handleQrCodePopup = () => {
        MySwal.fire({
            title: 'QR Code',
            html: (
                <div style={{ textAlign: 'center' }}>
                    <p>Scan this qr code using EarthID app <br />to add the certificate to your wallet.</p>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <QRCode value={`https://procreddb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`} size={200} />
                </div>
                </div>
                
            ),
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: 'Close',
        });
    }

    const handleViewCertificate = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Digital Certificate(VC)',
            html: <pre>{JSON.stringify(responseData[0].vc, null, 2)}</pre>,
            confirmButtonText: 'Verify',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
            didOpen: () => {
                const verifyButton = MySwal.getConfirmButton();
                verifyButton.addEventListener('click', () => {
                    const loadingSwal = MySwal.mixin({
                        title: 'Loading',
                        text: 'Performing verification...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            loadingSwal.showLoading();
                            verifyVC()
                                .then(() => {
                                    // Display success message upon successful verification
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Successful',
                                        text: '',
                                        icon: 'success',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                })
                                .catch((error) => {
                                    // Handle error case and display error message
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Error',
                                        text: 'An error occurred during verification.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: true,
                                        allowEscapeKey: true,
                                        showCancelButton: false,
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                });
                        },
                    });

                    loadingSwal.fire();
                });
            },
        });
    };

    const handleToggleCertificate = () => {
        setShowCertificate(true);
    };

    const handleToggleBadge = () => {
        setShowCertificate(false);
    };

    // Function to handle the PDF download
    const handleDownload = () => {
        if (showCertificate) {
          const pdfContainer = pdfContainerRef.current;
    
          const pdf = new jsPDF('l', 'pt', [500, 740]);
    
          html2canvas(pdfContainer, { scale: 3, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgData, 'JPEG', 0, 0, 740, 500);
            pdf.save(`training-certificate(${userName}).pdf`);
          });
        } else {
          const link = document.createElement('a');
          link.href = BadgeImg;
          link.download = `badge(${userName}).png`;
          link.click();
        }
      };


    const handleMailClick = () => {
        const subject = encodeURIComponent('Check out this certificate');
        const body = encodeURIComponent(`Here is the certificate link: ${certUrl}`);
        const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;

        const a = document.createElement('a');
        a.href = mailtoUrl;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
    };

    const handleShareLinkedIn = () => {
        // Open the LinkedIn URL in a new tab
        window.open(linkedInURL, '_blank');
    };
    // const handleShareLinkedIn = () => {
    //     // Wait until the LinkedIn SDK is fully loaded
    //     window.onload = function () {
    //       // Access the IN object and use it here
    //       IN.UI.Share().params({
    //         url: 'YOUR_LINKEDIN_SHARE_URL',
    //         mini: true,
    //       }).place();
    //     };
    //   };
    //   const handleViewCertificate = () => {
    //     const MySwal = withReactContent(Swal);

    //     MySwal.fire({
    //       title: 'Digital Certificate(VC)',
    //       html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,
    //       confirmButtonText: 'Close',
    //       customClass: {
    //         content: 'certificate-swal',
    //       },
    //     });
    //   };

    const handleHelpClick = () => {
        Swal.fire({
          title: 'Help',
          html: `
            <div style="text-align: left;">
              <p><strong>How to Use:</strong></p>
              <p>1. To view the certificate, click on the certificate icon.</p>
              <p>2. To view the badge, click on the badge icon.</p>
              <p>3. To download the certificate, click on the 'Download' button.</p>
              <p>4. For any other assistance, contact support at support@myearth.id</p>
            </div>
          `,
          icon: 'info',
          showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        popup: 'swal-custom-popup',
        title: 'swal-custom-title',
        closeButton: 'swal-custom-close-button'
      }
        });
      };
 

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {responseData && responseData.length > 0 ? (
                <div className="page-container">
                    <nav className="navbar-cert">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                       {!noBadge &&
                        <ul className="nav-menu">
                            <li className="nav-item"><a onClick={handleHelpClick}>Help</a></li>
                        </ul>
                       } 
                    </nav>
                    <div className="certificate-container" >
                        <div className='row'>
                            <div className='col-md-8'>
                            <section className={showCertificate ? 'certBackkt' : 'badgeBackkt'}>
                            <div >

                                {showCertificate ? (
                                    <div ref={pdfContainerRef} className="certificatedt">
                                        <div className="bodbox">

                                            <div className="headert">
                                                <div className='header1'>
                                                <div className="favico">
                                                    <img src={favicon}></img>
                                                </div>
                                                <div className="title">{certificationName}</div>
                                                </div>
                                               
                                                <div className="qrcode-containert">
                                                    <QRCode className='qrCodet' value={qrCodeUrl} />
                                                    <div className='scanme'>Scan Me</div>
                                                </div>
                                            </div>
                                            <div className='contentCert'>
                                           {!noBadge &&
                                            <div className="title1">Year 2024-2025</div>
                                           } 
                                            {
                                                
                                            }<div className="title1" style={{marginBottom: '20px'}}>
                                                {certType === "advisor" ? "This certifies that" : "This is to certify that"}
</div>
                                          

                                            <div className="body">
                                                <div className="name" style={{marginBottom: '40px'}}>{noBadge ? newUserName : userName}</div>
                                                {certDesc}
                                                {/* <div className="title1">has succesfully gone through the training curriculum and <br></br>has completed all the training processes of the company.<br></br>This certificate is issued on:<br></br>
                                                    {moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}.
                                                </div> */}
                                            </div>
                                            <br/>
                                            <div>
                                                {/* Add the QRCode component */}
                                                
                                                <div className='signature'>Issued By: EarthID</div>
                                                {/* <div className='signature1'>KPMG</div> */}
                                            </div>
                                            </div>
                                           


                                        </div>
                                    </div>) : (
                                    <div className="badge-containerrt">
                                        <img
                                            src={badgeImgUrl}
                                            alt="Badge Icon"
                                            //style={{ height: '450px', width: '380px' }}
                                            className={'badgeImgg'}
                                            onClick={handleToggleBadge}
                                        />
                                    </div>
                                )}

                            {!noBadge &&
                                <div className="icon-wrapper">
                                    <div className='row'>
                                        <div>
                                            <img
                                                src={CertificateIcon}
                                                alt="Certificate Icon"
                                                className={showCertificate ? 'active-icon' : 'inactive-icon'}
                                                onClick={handleToggleCertificate}
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src={colorBadge}
                                                alt="Badge Icon"
                                                //style={{ height: '150px', width: '130px' }}
                                                className={!showCertificate ? 'active-icon' : 'inactive-icon'}
                                                onClick={handleToggleBadge}
                                            />
                                        </div>
                                    </div>


                                </div>
}
                            </div>
                        
                        </section>
                            </div>
                        
                        <div className='rightsec col-md-4'>
                        <div className="footer2t">
                                        <div className='verify'>Certificate Verification</div>
                                        <div className='verify1'>
                                            <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                            This certificate is from a verified issuer
                                        </div>
                                        <div className='verify1'>
                                            {/* <div className="check">
                                            <img src={check}></img>
                                        </div> */}
                                            <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                            Certificate ID: {lastPart}
                                        </div>
                                        <button className="verify-btn" onClick={handlePopupOpen}>
                                            Verify
                                        </button>
                                        {/* <button className='verify-btn' onClick={handleViewCertificate}>View Digital Certificate</button> */}
                                         <button className='verify-btn' onClick={handleDownload}>Download</button>
                                    </div>
                        <div className="footer11t">
                                        <div className='sharecontainer'>
                                            <div className=''>
                                                <div className='verify'>Share Credential</div>
                                                <div className='verify1'>Show this credential on your social network</div>
                                            </div>
                                            <div className='yellowBadget'>
                                                <img src={badgeIcon}></img>

                                            </div>
                                        </div>

                                        <div className='row iconns-div'>
                                        <a className='shareIcon' onClick={handleMailClick} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon className='iconns' icon={faEnvelope} />
            </a>
                                            <FacebookShareButton
                                                url={certUrl}
                                            >
                                                <FontAwesomeIcon className='iconns' icon={faFacebook} />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={certUrl}
                                            >
                                                {/* <FontAwesomeIcon className='iconns' icon={faTwitter} /> */}
                                                <div className='iconns3'>
                                                <img src={Xlogo} className='iconns2'></img>
                                                </div>
                                               
                                              
                                            </TwitterShareButton>
                                            <WhatsappShareButton
                                                url={certUrl}
                                            >
                                                <FontAwesomeIcon className='iconns' icon={faWhatsapp} />
                                            </WhatsappShareButton>
                                            {/* <FontAwesomeIcon className='iconns' onClick={handleShareLinkedIn} icon={faLinkedin} /> */}
                                        </div>
                                        <button className='verify-btn' onClick={handleShareLinkedIn}>
                                            <FontAwesomeIcon className='iconns1' icon={faLinkedin} />  Share on LinkedIn
                                        </button>
                                        {/* <button className='verify-btn' onClick={handleQrCodePopup}>
                                            <FontAwesomeIcon className='iconns1' icon={faMobileScreenButton} />  Add to Wallet
                                        </button> */}
                                        {/* <a href="https://www.linkedin.com/company/myearthid/" target="_blank"><FontAwesomeIcon icon={faLinkedin}/></a> */}

                                    </div>
                                    
                        </div>
                        
                        </div>
                        

                    </div>
                    {/* Add the PDF download button */}
                    {/* Add the download button */}

                    <section className='infosect'>
                        <div >
                            <div className='row'>
                                <div className='leftsec col-md-8'>
                                    {/* <a href='https://myearth.id' target='blank'>
                                        <div className='issuerName'>

                                            <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} />

                                            EarthID

                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                        </div>
                                    </a> */}

                                    <div className='row'>
                                        <div className="check ">
                                            <img src={check}></img>
                                        </div>
                                        <div className='coursename '>{certificationName}</div>
                                    </div>
                                    {/* <div className='allBtns row'>
                                        <button className='pdfbtn' title='Download Pdf' onClick={handleDownload}>
                                            <FontAwesomeIcon className='fontIcon' icon={faFilePdf} />
                                            PDF
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faImage} />
                                            BADGE
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faShare} />
                                            EMAIL
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faPen} />
                                            EMBED
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faQuestionCircle} />
                                            HELP
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>

                                            MORE
                                            <FontAwesomeIcon className='fontIcon1' icon={faCaretDown} />
                                        </button>
                                    </div> */}
                                    <div className='studNameDiv'>
                                        <FontAwesomeIcon className='fontIcon' icon={faUser} />
                                        {noBadge ? newUserName : userName}
                                    </div>
                                    {/* <a href={`/certificates/${userName}`} target='_blank'>
                                        <div className='viewCredst'>View All Credentials</div>
                                    </a> */}


                                    {/* <div className='stuID'>ID: EarthID</div> */}


                                    <hr></hr>
                                    <div className='description'>
                                        {certDesc2}
                                    </div>
                                    <div className='skillsdiv'>
                                        <p>Skills/Knowledge</p>
                                        <div className='row'>
                                       
       {/* Conditionally render skillsGained if it exists */}
       {certType=="internship"? (
        responseData[0].vc.credentialSubject[0].internshipDetails.skillsGained.map((skill, index) => (
            <div key={index} className='allSkills'>
                {skill}
            </div>
        ))
    ) : (
        <>
            <div className='allSkills'>
                {skills.skill1}
            </div>
            <div className='allSkills'>
                {skills.skill2}
            </div>
            <div className='allSkills'>
                {skills.skill3}
            </div>
            <div className='allSkills'>
                {skills.skill4}
            </div>
        </>
    )}

    {/* Conditionally render technologies if it exists, otherwise render fallback skills */}
    {certType=="internship"? (
        responseData[0].vc.credentialSubject[0].internshipDetails.projects[0].technologies.map((technology, index) => (
            <div key={index} className='allSkills'>
                {technology}
            </div>
        ))
    ) : (
        <>
            
        </>
    )}
   
                                            {/* <div className='allSkills'>
                                            {skills.skill2}
                                            </div>
                                            <div className='allSkills'>
                                            {skills.skill3}
                                            </div>
                                            <div className='allSkills'>
                                            {skills.skill4}
                                            </div> */}
                                           {/* {noBadge && <>
                                            <div className='allSkills'>
                                            {skills.skill5}
                                            </div>
                                            <div className='allSkills'>
                                            {skills.skill6}
                                            </div>
                                           </> } */}
                                        </div>
                                    </div>
                                    <hr></hr>

                                    <div className='row'>
                                        <div className='issueon col-md-6'>
                                            <div>Issued On</div>
                                            <div>{moment(responseData[0].vc.issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                        <div className='expiry col-md-6'>
                                            <div>Expires On</div>
                                            {
    noBadge ? (
        <div>NA</div>
    ) : (
        <div>{moment(responseData[0].vc.expirationDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
    )
}
                                        </div>
                                    </div>

                                </div>
                                <div className='rightsec col-md-4'>
                                   
                                    <div className="footer2t">
                                        <div className='verify text-center' style={{ marginBottom: '30px', fontSize: '1.5rem' }}>About the Issuer</div>
                                        <div className="logowhite" style={{ marginBottom: '80px' }}>
                            <img src={logoWhite}></img>
                        </div>
                                        <div className='verify1 text-center' style={{ marginBottom: '50px', marginTop:'-50px', fontSize: '1rem' }}>
                                            EarthID is a multi-award-winning decentralized identity platform enabling organizations to 
                                            prevent identity frauds, drive compliance and foster customer trust.</div>
                                        {/* <button className="verify-btn" onClick={() => window.open('https://myearth.id', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />
                                        </button> */}
                                         <div className='row iconns-div'>
                                         <a className='shareIcon' href='https://myearth.id/' target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faGlobe} />
                                           </a>
                                            <a className='shareIcon' href="https://www.linkedin.com/company/myearthid/" target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faLinkedinIn} />
                                            </a>
                                            <a className='shareIcon' href="https://www.facebook.com/myearth.id/" target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faFacebook} />
                                           </a>
                                                <a className='shareIcon' href='https://twitter.com/earthid_ssi' target="_blank">
                                                {/* <FontAwesomeIcon className='iconns' icon={faTwitter} /> */}
                                                <div className='iconns3'>
                                                <img src={Xlogo} className='iconns2'></img>
                                                </div>
                                           </a>
                                                
                                           {/* <a className='shareIcon' href=''>
                                                <FontAwesomeIcon className='iconns' icon={faGlobe} />
                                           </a> */}
                                        </div>
                                        {/* <button className="verify-btn">
                                            View All Credentials
                                        </button> */}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </section>

                    {isPopupOpen && (
                        <div className="popup-container">
                            {isVerifying ? (
                                <div>
                                    <div className="spinner"></div>
                                    <div className="spinner-text">Verifying Credentials...</div>
                                </div>
                            ) : (
                                <div className="popup">
                                    <div className="popup-header">
                                        <div className="popup-title">Verification Details</div>
                                        <button className="popup-close" onClick={handlePopupClose}>
                                            X
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div className="popup-body">
                                        {isVerificationLoading[0] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Issuer...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1">
                                                        <div>This issuer is verified by EarthID</div>
                                                        <div>The issuer is found to be valid</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1 row">

                                                        <a href='https://myearth.id' target='blank'>
                                                            <div className='issuerNamepop'>

                                                                {/* <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} /> */}

                                                                EarthID

                                                                <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[1] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate ID...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>The authenticity of this credential can be independently verified.</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div className="footer22">
                                                            <div className='verify1'>
                                                                {/* <FontAwesomeIcon className='fontIcon22' icon={faCheckCircle} /> */}
                                                                Certificate ID: <span>{lastPart}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[2] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Owner...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>


                                                    <div className="verification-result1">
                                                        <div>The owner of this credential has been verified</div>
                                                        <div className='row'>
                                                            <div className="verification-label">Issued to:</div>
                                                            {verificationData[3] && <div>{verificationData[3]}</div>}
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[3] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate Status...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className='datess row'>
                                                    <div className="verification-itemm">
                                                        <div className="verification-result">
                                                            <div className="success">&#10004;</div>
                                                            <div></div>
                                                        </div>
                                                        <div className='issuedOn'>
                                                            <div className="verification-label">Issued on:</div>

                                                            <div className="verification-result1">
                                                                {verificationData[0] && <div>{verificationData[0]}</div>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="verification-itemm">
                                                        <div className="verification-result">
                                                            {/* <div className="success">&#10004;</div> */}
                                                            <div></div>
                                                        </div>
                                                        <div className='expiresOn'>
                                                            <div className="verification-label">Expires on:</div>

                                                            <div className="verification-result1">
                                                                {verificationData[1] && <div>{verificationData[1]}</div>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[4] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Credential Data...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success1"><img src={check}></img></div>
                                                    </div>
                                                    <div className="verification-label"></div>

                                                    <div className="verification-result11">
                                                        <div className='verified'>This {certificationName} is VERIFIED</div>

                                                        <div className='verified1'>This digital credential was securely issued via EarthID and its information is valid.</div>


                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <footer className="footerr">
                        <div className="footerr-text">
                            Powered by EarthID
                        </div>
                    </footer>
                </div>
            ) : (
                Swal.fire({
                    title: "No Certificate Found",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Go Back",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the home page
                        window.location.href = "/uploadDoc";
                    }
                })
            )}
        </>

    );
};

export default Training;
