import React, {useEffect, useState} from 'react'
import './allCreds.scss'
import { useParams } from "react-router-dom";

import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import logoWhite from '../../Logo-EarthID-white.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import studentPhoto from '../../img/studentIcon.png'
import kpTraining from '../../img/kp-training.png'
import kpProfMem from '../../img/kp-profmem.png'

const AllCreds = () => {

    const [responseDataTraining, setResponseDataTraining] = useState(null);
    const [responseDataID, setResponseDataID] = useState(null);
    const [responseDataProfMem, setResponseDataProfMem] = useState(null);
    const [responseDataProject, setResponseDataProject] = useState(null);

    console.log('This is the response data:', responseDataTraining)

const { userName } = useParams();


useEffect(() => {
    const fetchData = async () => {
      try {
        const prefixes = {
          "TrainingCertificate": setResponseDataTraining,
          "ProfessionalMembershipCertificate": setResponseDataProfMem,
          "EmploymentCertificate": setResponseDataID,
          "ProjectCompletionCertificate": setResponseDataProject,
        };

        for (const [prefix, setResponseData] of Object.entries(prefixes)) {
          const userNamee = `${prefix}${userName}`;
          console.log('Username', userNamee);
          const cidsResponse = await fetch(`https://procreddb.myearth.id/api/vcnew/company/${userNamee}/CertificateVc`);
          const cidJson = await cidsResponse.json();
          console.log('CidsResponse', cidJson);

          if (cidJson && cidJson.response && cidJson.response.length > 0) {
            setResponseData(cidJson.response);
          } else {
            console.log('Certificate not generated for', prefix);
          }
        }
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchData();
  }, [userName]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(date);
  };


  

  return (
    <>
     <div className="page-container">
     <nav className="navbar-cert">
                        <div className="logoAll">
                            <img src={logo}></img>
                        </div>
                        {/* <ul className="nav-menu">
                            <li className="nav-item"><a href={`/certificate/training/${userName}`}>Help</a></li>
                        </ul> */}
                    </nav>
                    <div className='userNameSec'>
                      <div className='userNameSec1 container'>
                      <div className='userImg'>
    <img className='userImg1' src={studentPhoto}></img>
</div>
<div>
<p className='userNameall'>{userName}</p>
<p className='credText'>2 Credentials | 1 Issuer</p>
</div>
                      </div>


                        
                    </div>
                   <div className='cardSecAll'>
                    <div className='cardSecAll1 container row'>
                        {responseDataTraining &&
                        <a href={`/certificate/training/${userName}`}>
<div class="blog-card ">
    <div className='blogImg1'>
    <img src={kpTraining} alt="Blog Image" class="blog-card__image" />
    </div>
                               
                                <div class="blog-card__content">
                                  <h2 class="blog-card__title">Training Certificate</h2>
                                  <p className="blog-card__date">{formatDate(responseDataTraining[0].createdAt)}</p>
                                  <p class="blog-card__issued-by">Issued by: EarthID</p>
                                </div>
                              </div>
                        </a>
                                
                        }
                          {responseDataProfMem &&
                           <a href={`/certificate/professional-membership/${userName}`}>
                                <div class="blog-card ">
                                <div className='blogImg1'>
                                <img src={kpProfMem} alt="Blog Image" class="blog-card__image" />
                                </div>
                                <div class="blog-card__content">
                                  <h2 class="blog-card__title">Professional Membership Certificate</h2>
                                  <p className="blog-card__date">{formatDate(responseDataProfMem[0].createdAt)}</p>
                                  <p class="blog-card__issued-by">Issued by: EarthID</p>
                                </div>
                              </div>
                              </a>
                        }
                          {/* {responseDataID &&
                           <a href={`/certificate/employment/${userName}`}>
                                <div class="blog-card ">
                                <img src={kpTraining} alt="Blog Image" class="blog-card__image" />
                                <div class="blog-card__content">
                                  <h2 class="blog-card__title">Employment Certificate</h2>
                                  <p className="blog-card__date">{formatDate(responseDataID[0].createdAt)}</p>
                                  <p class="blog-card__issued-by">Issued by: EarthID</p>
                                </div>
                              </div>
                              </a>
                        }
                          {responseDataProject &&
                           <a href={`/certificate/project-completion/${userName}`}>
                                <div class="blog-card ">
                                <img src={kpTraining} alt="Blog Image" class="blog-card__image" />
                                <div class="blog-card__content">
                                  <h2 class="blog-card__title">Project Completion Certificate</h2>
                                  <p className="blog-card__date">{formatDate(responseDataProject[0].createdAt)}</p>
                                  <p class="blog-card__issued-by">Issued by: EarthID</p>
                                </div>
                              </div>
                              </a>
                        } */}

                    </div>
     
                   </div>
                    
     </div>
     <footer className="footerrall">
                        <div className="footerr-text">
                            Powered by EarthID
                        </div>
                    </footer>
      
    </>
  )
}

export default AllCreds